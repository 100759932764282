import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import parse from 'html-react-parser'
import { wpautop } from '@utils/wpautop'

import FormBlogNewsletter from '@components/global/forms/BlogNewsletter'

import * as cx from './BlogContent.module.scss'

// SVG
import SvgArrowThickAlt from '@svg/arrowAltThick.inline'

const BlogContent = ({
  image,
  primaryCategory,
  categories,
  title,
  author,
  date,
  readTime,
  content,
}) => {
  return (
    <section id="post-content">
      <div
        className={clsx([cx.featuredImage])}
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>

      <div className={clsx([cx.wrapper])}>
        <div className={clsx([cx.arrow])}>
          <SvgArrowThickAlt />
        </div>
        <div className={clsx([cx.badge])}>
          <div className={`badge`}>
            {primaryCategory?.node?.name ? primaryCategory?.node?.name : 'Blog'}
          </div>
        </div>
        <article className={clsx(['container'], cx.container)}>
          <h1 className={clsx(['heading-2', cx.title])}>{title}</h1>
          <div className={clsx(['small-heading strong', cx.author])}>
            <span>By {author}</span> / <span>Published {date}</span> /{' '}
            <span>{readTime} min read</span>
          </div>

          <div id="post-content" className={clsx([cx.content])}>
            {parse(wpautop(content, 'subtitle-5'))}
          </div>

          <FormBlogNewsletter />
        </article>
      </div>
    </section>
  )
}

BlogContent.propTypes = {
  image: PropTypes.string.isRequired,
  primaryCategory: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  readTime: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default BlogContent
