import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useBlogMetadata } from '@hooks/blog-metadata'
import { modifyImageKeys } from '@utils/modifyImageKeys'
import parse from 'html-react-parser'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './BlogPostsV1B.module.scss'

import backgroundImage from '/content/assets/blog/cards-v1b-bg.png'

const BlogPostsV1B = ({
  background = backgroundImage,
  wrapperClassName = '',
  count = 2,
  title = 'Latest Blog Posts',
  subtitle = 'Need more Real Estate Marketing ideas?',
  showSubtitle = true,
}) => {
  const items = useBlogMetadata().allWpPost.edges

  return (
    <section
      className={`${cx.section} ${wrapperClassName}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className={cx.heading}>
        {showSubtitle && <p className="subtitle-7">{subtitle}</p>}
        <h5 className="heading-5">{title}</h5>
      </div>

      <div className={cx.items}>
        {items.map((item, index) => {
          if (index < count) {
            return (
              <div key={item.node.id} className={cx.item}>
                <Link to={item.node.slug} className="image-responsive">
                  <canvas width={620} height={204}></canvas>
                  <ElementImageFile
                    src={modifyImageKeys(item.node?.featuredImage?.node)}
                    alt={item.node.title}
                    lazy={true}
                  />
                </Link>
                <div className={cx.hr}></div>
                <div className={cx.content}>
                  <div className={`badge`}>
                    {item.node.primaryCategory?.node?.name
                      ? item.node.primaryCategory?.node?.name
                      : 'Blog'}
                  </div>
                  <h3 className="subtitle-2">{item.node.title}</h3>
                  <p className="default-body large line-clamp-3">
                    {parse(item.node.excerpt)}
                  </p>
                  <Link to={item.node.slug} className="primary-button">
                    Read the Blog
                  </Link>
                </div>
              </div>
            )
          }
        })}
      </div>
    </section>
  )
}

BlogPostsV1B.propTypes = {
  background: PropTypes.string,
  wrapperClassName: PropTypes.string,
  count: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  showSubtitle: PropTypes.bool,
}

export default BlogPostsV1B
