import React from 'react'
import PropTypes from 'prop-types'

import DeviceMobile from '../Mobile'
import DeviceTablet from '../Tablet'
import * as cx from './ProductGalleryPhoneTablet.module.scss'

const ProductGalleryPhoneTablet = ({
  wrapperClass = '',
  mobileSrc = '',
  tabletSrc = '',
}) => {
  return (
    <div className={`${cx.wrapper} ${wrapperClass}`}>
      <canvas width={510} height={352} className={cx.placeholder}></canvas>
      <DeviceMobile imageSrc={mobileSrc} className={`${cx.mobile} mobile`} />
      <DeviceTablet
        imageSrc={tabletSrc}
        className={`${cx.tablet} tablet-landscape`}
      />
    </div>
  )
}

ProductGalleryPhoneTablet.propTypes = {
  wrapperClass: PropTypes.string,
  mobileSrc: PropTypes.string,
  tabletSrc: PropTypes.string,
}

export default ProductGalleryPhoneTablet
