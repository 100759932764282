import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

// Inputs
import Input from '../fields/Input'

// CSS
import * as cx from './FormBlogNewsletter.module.scss'

const FormBlogNewsletter = () => {
  return (
    <div id="form-blog-newsletter" className={clsx(cx.content)}>
      <form className={clsx(cx.form)}>
        <h3 className="heading-3">Did you enjoy reading this article?</h3>
        <p className="default-body large">
          Sign up for more updates with our Agent Intelligence Newsletter!
        </p>
        <div className={clsx(cx.control)}>
          <div className="form-control">
            <input
              type="email"
              placeholder="Enter you E-mail"
              className="small"
            />
          </div>
          <div className="form-control">
            <button type="submit" className="primary-button large">
              Sign me up
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

FormBlogNewsletter.propTypes = {}

export default FormBlogNewsletter
