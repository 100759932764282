import React from 'react'

const SvgArrowThickAlt = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 13"
      style={{ enableBackground: 'new 0 0 18 13' }}
      fill="none"
      className="fill-current"
    >
      <path d="M17.9999 2.49108C18.0006 2.74723 17.9582 3.00032 17.8759 3.23175C17.7935 3.46319 17.6734 3.66708 17.5242 3.82846L9.81069 12.11C9.58065 12.3621 9.29211 12.5 8.99433 12.5C8.69656 12.5 8.40802 12.3621 8.17798 12.11L0.464424 3.53698C0.201884 3.24595 0.0367824 2.82774 0.00543999 2.37435C-0.0259024 1.92097 0.0790816 1.46955 0.297296 1.1194C0.515511 0.769252 0.829082 0.549057 1.16903 0.507255C1.50897 0.465454 1.84744 0.605471 2.10998 0.896503L9.00076 8.56074L15.8915 1.15369C16.0802 0.944027 16.31 0.810843 16.5537 0.769899C16.7974 0.728955 17.0448 0.781965 17.2666 0.922657C17.4884 1.06335 17.6753 1.28584 17.8053 1.56379C17.9352 1.84174 18.0028 2.16353 17.9999 2.49108Z" />
    </svg>
  )
}

export default SvgArrowThickAlt
