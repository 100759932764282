import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

// Components
import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import BlogContent from '@components/pages/blog/content'
import BlogPostsV1B from '@components/global/blog/BlogPostsV1B'
import PackageBestForYou from '@components/pages/best-re-agent-websites-2022/PackageBestForYou'
import LeadContactForm from '@components/global/forms/LeadContactForm/Index'

// Element
import ElementImageFile from '@components/global/element/ImageFile'

// CSS
import * as cx from './BlogPost.module.scss'

// SVG
import SvgArrowThickAlt from '@svg/arrowAltThick.inline'

const BlogPostTemplate = ({
  data: {
    previous,
    next,
    post,
    packageWorksAIXTablet,
    packageWorksAIXMobile,
    packageWorksAPTablet,
    packageWorksAPMobile,
    packageWorksSCTablet,
    packageWorksSCMobile,
    packageWorksISTablet,
    packageWorksISMobile,
  },
}) => {
  return (
    <LayoutInnerPage>
      <Seo title={post.title} description={post.excerpt} />
      <BlogContent
        image={post.featuredImage?.node?.publicUrl}
        primaryCategory={post.primaryCategory}
        categories={post.categories}
        title={post.title}
        author={post.author.node.name}
        date={post.date}
        readTime={12}
        content={post.content}
      />
      <BlogPostsV1B title="Ready to Read More?" showSubtitle={false} />
      <PackageBestForYou
        packages={[
          {
            title: 'Agent Image X',
            description:
              'A solid, easy to build website that gets the job done',
            link: '/real-estate-websites/agent-image-x/',
            tablet: getImage(packageWorksAIXTablet),
            mobile: getImage(packageWorksAIXMobile),
          },
          {
            title: 'Agent Pro',
            description: 'A wide range of professionally designed templates',
            link: '/real-estate-websites/',
            tablet: getImage(packageWorksAPTablet),
            mobile: getImage(packageWorksAPMobile),
          },
          {
            title: 'Semi-Custom',
            description: 'A unique website styled to showcase your brand',
            link: '/real-estate-websites/',
            tablet: getImage(packageWorksSCTablet),
            mobile: getImage(packageWorksSCMobile),
          },
          {
            title: 'Imagine Studio',
            description:
              'A trend-setting website that is designed to meet your vision ',
            link: '/real-estate-websites/',
            tablet: getImage(packageWorksISTablet),
            mobile: getImage(packageWorksISMobile),
          },
        ]}
      />
      <LeadContactForm
        version="LCF05"
        modelOrBackground="Gerald"
        title="Free Consultation"
        subtitle="Get in touch with us for a"
        submitLabel="I want a stunning website!"
        leadName={`Consultation: ${post.title}`}
        layout="default"
      />
    </LayoutInnerPage>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      author {
        node {
          name
        }
      }
      date(formatString: "MMMM DD, YYYY")
      primaryCategory {
        node {
          name
          slug
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      featuredImage {
        node {
          width
          height
          publicUrl
          sizes
          srcSet
          altText
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    packageWorksAIXTablet: file(
      relativePath: {
        eq: "real-estate-websites/agentimagex/AIX_Seneca_720x590.jpg"
      }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksAIXMobile: file(
      relativePath: {
        eq: "real-estate-websites/agentimagex/AIX_Seneca_300x650.jpg"
      }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksAPTablet: file(
      relativePath: { eq: "real-estate-websites/agentpro/AP_Maven_720x590.jpg" }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksAPMobile: file(
      relativePath: { eq: "real-estate-websites/agentpro/AP_Maven_300x650.jpg" }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksSCTablet: file(
      relativePath: {
        eq: "real-estate-websites/semi-custom/sample/Ela_Luxurious_720x590.jpg"
      }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksSCMobile: file(
      relativePath: {
        eq: "real-estate-websites/semi-custom/sample/Ela_Luxurious_300x650.jpg"
      }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksISTablet: file(
      relativePath: {
        eq: "real-estate-websites/imagine-studio/sample/Ivan_Estrada_720x590.jpg"
      }
    ) {
      id
      ...BlogPostImage
    }
    packageWorksISMobile: file(
      relativePath: {
        eq: "real-estate-websites/imagine-studio/sample/Ivan_Estrada_300x650.jpg"
      }
    ) {
      id
      ...BlogPostImage
    }
  }

  fragment BlogPostImage on File {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
    }
  }
`
